<template>
  <ion-page>

    <ion-content class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'SessionCheck',
  data () {
    return {
      inserat: null,
      userName: null,
      userPassword: null,
      isLoading: false,
      authError: false
    }
  },
  components: {
    IonContent,
    IonPage,
    IonSpinner,
  },
  created() {
    // check if there is an active login session, if yes retrieve new token
    if (localStorage.userToken && localStorage.userId && localStorage.refreshToken){
        axios.post(process.env.VUE_APP_API_URL + '/token/refresh', {
          'refresh_token' : localStorage.refreshToken,
        }).then(response => {
            localStorage.userToken = response.data.token;
            localStorage.refreshToken = response.data.refresh_token;
            // send to main screen
            this.$router.push({ path: '/all' });
        }).catch(() => {
          // redirect to login
          this.$router.push({ path: '/login' });
        });
    } else {
      this.$router.push({ path: '/login' });
    }
  }
});

</script>

<style scoped>

ion-spinner {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}


</style>